import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BackgroundGradientAnimation from './Animations/background-gradient-animation';
import { JoinPageParameters } from './join';
import Register from '../Registration/register';

const JoinMobile = () => {
    const [label, setLabel] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState('');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const parameter = urlParams.get('q');
        if (!parameter) return;
        const label = JoinPageParameters.find((param) => param.value === parameter)?.label;
        setLabel(label);
    }, []);

    return (
        <React.Fragment>
            <BackgroundGradientAnimation />

            <Title>Find the {label ? <span>{label}</span> : null} maps you are looking for</Title>

            <Container>
                <Register
                    email={email}
                    onEmailChange={(email: string) => setEmail(email)}
                    onClose={() => {
                        null;
                    }}
                    handleClickLogin={() => {
                        null;
                    }}
                    onRegisterSuccess={() => {
                        null;
                    }}
                />
            </Container>

            <ImageContainer>
                <Image src="/assets/join-page/asset1.jpg" />
                <Image src="/assets/join-page/asset2.jpg" />
                <Image src="/assets/join-page/asset3.gif" />
            </ImageContainer>
        </React.Fragment>
    );
};

export default JoinMobile;

const Container = styled.div`
    margin: 20px;
    border: 1px solid #eed926;
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const Title = styled.h2`
    color: ${({ theme }) => theme.color.white};
    font-size: 2.5rem;
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;

    span {
        color: ${({ theme }) => theme.color.yellow};
    }
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    max-width: 25%;
    height: 90%;
    margin: 10px;
`;
