import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { keyframes, css } from 'styled-components';
import MapSearchDrawListingCard from '../ListingsDrawer/SearchListings/map-search-draw-listing-card';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ApiAnalytics from '../../../../api/api-analytics';
import { ListingDTO, AnalyticsAction, AnalyticsNote } from '../../../../api/model';
import UriHelper from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
    actionSetSearchDrawListings,
} from '../../../../store/Map/MapSelection/actions';
import {
    selectHighlightedListingGroup,
    selectSelectedClickToPreview,
} from '../../../../store/Map/MapSelection/selectors';
import SideDrawerNoMaps from './side-drawer-no-maps';
import SideDrawerLoader from './side-drawer-loader';
import SideDrawerNested from './side-drawer-nested';
import MapSearchDrawPreviewDetails from '../ListingsDrawer/SearchListings/map-search-draw-preview-details';

export type MapDrawerTypes = 'MapSearch' | 'Bookmarks' | 'MyMaps';
interface MapSearchDrawAOIResultsProps {
    type: MapDrawerTypes;
    listings: ListingDTO[] | undefined;
    exitingListings: Set<number>;
    listingsCount: number;
    filteredCount: number;
    isLoading?: boolean;
    onLoadMore: () => void;
    heightPadding: string;
}

const SideDrawerInfiniteScroll = ({
    type,
    listings,
    exitingListings,
    listingsCount,
    filteredCount,
    isLoading,
    onLoadMore,
    heightPadding,
}: MapSearchDrawAOIResultsProps) => {
    const [nestedListingPreview, setNestedListingPreview] = useState<ListingDTO>();
    const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);
    const clickToPreview = useSelector(selectSelectedClickToPreview);

    const history = useHistory();
    const dispatch = useDispatch();

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));

    const onMouseEnter = (tileLayerId: number) => {
        if (isDrawerExpanded) {
            setTileLayerHighlighted(undefined);
            dispatch(actionSetSearchDrawListings([]));
        } else {
            setTileLayerHighlighted(tileLayerId);
            dispatch(
                actionSetSearchDrawListings([listings?.find((listing) => listing.id === tileLayerId) as ListingDTO])
            );
            invalidateHighlightedTileLayerGroup();
        }
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
        dispatch(actionSetSearchDrawListings([]));
    };

    const onClickListing = (listingId: number) => {
        // While not a fan of overriding the back behavior it has been requested.
        // We are been specific here so it does not get messy.
        // Behavior is to override the browser back behavior by pushing the pos (if available) into the history stack
        history.push(UriHelper.stripOrigin(window.location.href));
        ApiAnalytics.postAnalyticsListing(AnalyticsAction.VIEW, AnalyticsNote.FEATURED_DRAW, listingId);
        Analytics.Event('Side Drawer', 'Clicked To View Map In Scroll', listingId);
        UriHelper.navigateToPath(`/maps/${listingId}`);
    };

    const handleHighlightedMap = (listingId: number) => {
        return (
            highlightedTileLayerGroup?.tileLayers.length === 1 &&
            highlightedTileLayerGroup.tileLayers[0].id === listingId
        );
    };

    return (
        <React.Fragment>
            <ResultsContainer
                initialScrollY={0}
                dataLength={listings?.length ?? 0}
                next={onLoadMore}
                hasMore={(listings?.length ?? 0) < listingsCount}
                loader={(listings?.length ?? 0) < listingsCount && filteredCount !== 0 && <SideDrawerLoader />}
                height={`calc(100vh - ${heightPadding})`} // height of the header and a trim off the bottom
            >
                {listings &&
                    listings.map((listing, index) => (
                        <React.Fragment>
                            {clickToPreview ? (
                                <SideDrawerNested
                                    key={`${listing.id}-${index}`}
                                    closeDrawer={nestedListingPreview?.id !== listing.id}
                                    width={'500px'}
                                    controlText={`View ${listing.title}`}
                                    onExpandedChange={(expanded) => setIsDrawerExpanded(expanded)}
                                    controlComponent={
                                        <Container
                                            key={`${index}-${listing.id}`}
                                            onMouseEnter={() => onMouseEnter(listing.id)}
                                            onMouseLeave={() => onMouseLeave()}
                                            onClick={(e) => {
                                                if (clickToPreview) {
                                                    Analytics.Event(
                                                        'Side Drawer',
                                                        'Clicked list item to view preview details'
                                                    );
                                                    setNestedListingPreview(listing);
                                                    return;
                                                }
                                                e.preventDefault();
                                                onClickListing(listing.id);
                                            }}
                                            title={listing.title}
                                            isHighlighted={handleHighlightedMap(listing.id)}
                                            isExiting={exitingListings.has(listing.id)}
                                            index={index}
                                        >
                                            <MapSearchDrawListingCard listing={listing} />
                                        </Container>
                                    }
                                >
                                    <MapSearchDrawPreviewDetails listing={listing} />
                                </SideDrawerNested>
                            ) : (
                                <Container
                                    key={`${index}-${listing.id}`}
                                    onMouseEnter={() => onMouseEnter(listing.id)}
                                    onMouseLeave={() => onMouseLeave()}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        onClickListing(listing.id);
                                    }}
                                    title={listing.title}
                                    isHighlighted={handleHighlightedMap(listing.id)}
                                    isExiting={exitingListings.has(listing.id)}
                                    index={index}
                                >
                                    <MapSearchDrawListingCard listing={listing} />
                                </Container>
                            )}
                        </React.Fragment>
                    ))}
                {listings?.length === 0 && !isLoading ? <SideDrawerNoMaps type={type} /> : null}
                {isLoading && listingsCount === 0 && exitingListings?.size === 0 && <SideDrawerLoader />}
            </ResultsContainer>
        </React.Fragment>
    );
};

export default SideDrawerInfiniteScroll;

const ResultsContainer = styled(InfiniteScroll)`
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 48px;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    height: 48px;
  }
  to {
    opacity: 0;
    height: 0;
  }
`;

const Container = styled.a<{ isHighlighted: boolean; isExiting: boolean; index: number }>`
    cursor: pointer;
    height: 70px !important;
    width: 98%;
    min-width: 98%;
    border-radius: 6px;
    overflow: hidden;
    display: block;
    position: relative;
    margin: 0 0 5px 0;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.15);
    opacity: 0;

    ${({ isExiting, index }) =>
        css`
            animation: ${isExiting ? fadeOut : fadeIn} 0.05s ease-in-out forwards;
            animation-delay: ${index * 0.025}s;
        `}

    &:hover {
        border: 1px solid ${(props) => props.theme.color.yellow};
        text-decoration: none;
    }
`;
