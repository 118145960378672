import { orderByOptions } from './SearchListingsFilters/map-search-orderby-filter';
import { useSelector } from 'react-redux';
import {
    selectSelectedClickToSearch,
    selectSelectedIsSearchActive,
    selectSelectedSearchCategory,
    selectSelectedSearchOrderBy,
} from '../../../../../store/Map/MapSelection/selectors';
import styled from 'styled-components';
import { listingCategoryOptions } from '../../../../../api/model';

const MapSearchNestedFilterIcon = () => {
    const searchCategory = useSelector(selectSelectedSearchCategory);
    const searchOrderBy = useSelector(selectSelectedSearchOrderBy);
    const clickToSearch = useSelector(selectSelectedClickToSearch);
    const isSearchActive = useSelector(selectSelectedIsSearchActive);

    const getLabelFromValue = (value: string | undefined, options: { value: string; label: string }[]) => {
        const option = options.find((option) => option.value === value);
        return option ? option.label : '';
    };

    const orderByLabel = getLabelFromValue(searchOrderBy, orderByOptions);
    const categoryLabel = getLabelFromValue(searchCategory, listingCategoryOptions);

    const activeFiltersCount =
        (searchCategory ? 1 : 0) + (searchOrderBy ? 1 : 0) + (clickToSearch ? 1 : 0) || (isSearchActive ? 1 : 0);

    const orderBytext = `${orderByLabel}${searchCategory && searchOrderBy ? ' and ' : ''}${categoryLabel}${
        clickToSearch ? (orderByLabel || categoryLabel ? ' and ' : '') + 'Click-to-search enabled' : ''
    }`;

    const filterText = isSearchActive ? 'Search by keywords' : orderBytext;

    return (
        <FilterIconContainer>
            <FilterIcon src="/assets/side-drawer/filter.svg" />
            {activeFiltersCount ? (
                <FilterIconActiveFilters title={filterText}>{activeFiltersCount}</FilterIconActiveFilters>
            ) : null}
        </FilterIconContainer>
    );
};

export default MapSearchNestedFilterIcon;

const FilterIcon = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;
    padding: 10px;
`;

const FilterIconContainer = styled.div`
    position: fixed;
    z-index: 999;
    margin-top: -3px;
    margin-left: 275px;
    cursor: pointer;
`;

const FilterIconActiveFilters = styled.div`
    position: absolute;
    background-color: rgb(238, 217, 38);
    color: black;
    top: 0px;
    right: -2px;
    border-radius: 50%;
    width: 14px;
    height: 15px;
    text-align: center;
    font-size: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 6px 8px;
    transition: box-shadow 0.3s, transform 0.3s;

    &:hover {
        box-shadow: rgba(0, 0, 0, 0.8) 0px 6px 12px;
        transform: translateY(-1px);
    }
`;
