import { useDispatch, useSelector } from 'react-redux';
import { StyledButton } from '../../../../../Shared/styled-button';
import styled from 'styled-components';
import Analytics from '../../../../../../lib/user-analytics';
import { selectSelectedClickToPreview } from '../../../../../../store/Map/MapSelection/selectors';
import { actionSetSelectedClickToPreview } from '../../../../../../store/Map/MapSelection/actions';

const MapSearchClickToPreview = () => {
    const clickToPreview = useSelector(selectSelectedClickToPreview);
    const dispatch = useDispatch();

    const clickToPreviewButtonText = clickToPreview ? 'Deactivate brief preview' : 'Activate brief preview';
    const previewText = !clickToPreview
        ? 'Preview the map before you interact with it'
        : 'Open the map and interact with it';

    return (
        <ClickToPreviewButton
            title={previewText}
            onClick={() => {
                dispatch(actionSetSelectedClickToPreview(!clickToPreview));
                Analytics.Event('Side Drawer', `Click to preview ${clickToPreview ? 'Deactivated' : 'Activated'}`);
            }}
        >
            {clickToPreviewButtonText}
        </ClickToPreviewButton>
    );
};

export default MapSearchClickToPreview;

const ClickToPreviewButton = styled(StyledButton)`
    margin: 15px auto 10px auto;
    border-radius: 6px;
    padding: 8px 15px;
    width: 230px;
`;
