import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ListingType } from '../../../../../api/model';
import SoarHelper, { isMobileVersion } from '../../../../../lib/soar-helper';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import EmbeddedMapDialog from '../../EmbedMap/embedded-map-dialog';
import ActiveMapBookmark from '../active-map-bookmark';
import PinMap from './pin-map';
import ShareCopy from './share-copy-link';
import ShareLike from './share-like';
import ShareLinks from './social-links';
import ShareMobileAppButton from './share-mobile-app-button';
import ReportMap from '../../../../Shared/ReportMap/report-map';
import SideDrawerSocial, { StyledButton } from './side-drawer-social';

interface ShareComponentProps {
    listingPage?: boolean;
}

const MapOptions = (props: ShareComponentProps) => {
    const selectedMap = useSelector(selectActiveAllMap);

    if (!selectedMap) {
        return <React.Fragment />;
    }

    // Do not show share links for satellite orders, as they are private
    if (selectedMap.listingType === ListingType.ORDER) {
        return <React.Fragment />;
    }

    // FOLLOWING REVIEWED TO MATCH CURRENT PRODUCTION CODE 26/9/2024
    // Do not display share options for iPad or Android tablet on subdomains
    if ((SoarHelper.isIPad() && !SoarHelper.isSoar()) || (SoarHelper.isAndroidTablet() && !SoarHelper.isSoar())) {
        return <React.Fragment />;
    }

    // Do not display share options for mobile devices on subdomains
    if (!SoarHelper.isSoar() && isMobileVersion) {
        return <React.Fragment />;
    }

    if (!SoarHelper.isSoar() && !isMobileVersion) {
        return (
            <DrawShareContainer>
                <DrawShareIcons>
                    <SideDrawerSocial />
                </DrawShareIcons>
                <DrawShareIcons>
                    <StyledButton>{!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}</StyledButton>
                    <StyledButton>
                        <ReportMap />
                    </StyledButton>
                </DrawShareIcons>
            </DrawShareContainer>
        );
    }

    if (SoarHelper.isSoarAndroid()) {
        return (
            <ShareContainer>
                <ShareIcons>
                    <ShareMobileAppButton />
                    <ShareCopy />
                </ShareIcons>

                <ShareIcons>
                    <ShareLike selectedMap={selectedMap} />
                    {!props.listingPage && <PinMap />}
                    <ActiveMapBookmark mapId={selectedMap.id} />
                    {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                </ShareIcons>
            </ShareContainer>
        );
    }

    if (SoarHelper.isSoarIOS()) {
        return (
            <ShareContainer>
                <ShareIcons>
                    {/* TODO: Re-active when iOS wrapper updated to handle share link */}
                    {/* <ShareMobileAppButton /> */}
                    <ShareCopy />
                </ShareIcons>

                <ShareIcons>
                    <ShareLike selectedMap={selectedMap} />
                    {!props.listingPage && <PinMap />}
                    <ActiveMapBookmark mapId={selectedMap.id} />
                    {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                </ShareIcons>
            </ShareContainer>
        );
    }

    if (SoarHelper.isIPad() || SoarHelper.isAndroidTablet()) {
        return (
            <IPadShareContainer>
                <ShareMobileAppButton />
                <ShareCopy />
                <ShareLike selectedMap={selectedMap} />
                {!props.listingPage && <PinMap />}
                <ActiveMapBookmark mapId={selectedMap.id} />
                {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
            </IPadShareContainer>
        );
    }

    if (SoarHelper.isMobile()) {
        return (
            <ShareContainer>
                <ShareIcons>
                    <ShareLinks />
                    <ShareCopy />
                </ShareIcons>

                <ShareIcons>
                    <ShareLike selectedMap={selectedMap} />
                    {!props.listingPage && <PinMap />}
                    <ActiveMapBookmark mapId={selectedMap.id} />
                    {!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}
                </ShareIcons>
            </ShareContainer>
        );
    }

    return (
        <DrawShareContainer>
            <DrawShareIcons>
                <SideDrawerSocial />
            </DrawShareIcons>
            <DrawShareIcons>
                <StyledButton>{!props.listingPage && <EmbeddedMapDialog tileLayer={selectedMap} />}</StyledButton>
                <StyledButton>
                    <ReportMap />
                </StyledButton>
            </DrawShareIcons>
        </DrawShareContainer>
    );
};

export default MapOptions;

const DrawShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const DrawShareIcons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    .embed-map-icon {
        height: 20px;
        margin: 0px;
        margin-top: 3px;
        font-size: 16px;
    }

    .report-map-icon {
        height: 20px;
        margin: 0px;
        margin-top: -5px;
        font-size: 16px;
    }
`;

const ShareContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;

    @media screen and (max-width: 600px) {
        margin: 15px 15px 15px 10px;
        flex-direction: row;
        align-items: unset;
    }
`;

const ShareIcons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    @media screen and (max-width: 600px) {
        margin: 0px;
    }
`;

const IPadShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 10px 0px;

    .embed-map-icon,
    .fa-thumbtack,
    .fa-share {
        margin: 0px;
    }
`;
