import MapSearchCategoryFilter from './SearchListingsFilters/map-search-category-filter';
import MapSearchClickToPreview from './SearchListingsFilters/map-search-click-to-preview';
import MapSearchClickToSearch from './SearchListingsFilters/map-search-click-to-search';
import MapSearchOrderByFilter from './SearchListingsFilters/map-search-orderby-filter';
import styled from 'styled-components';

const MapSearchNestedFilterContent = () => {
    return (
        <MapSearchNestedFilterContainer>
            <MapSearchOrderByFilter />
            <MapSearchCategoryFilter />
            <MapSearchClickToSearch />
            <MapSearchClickToPreview />
        </MapSearchNestedFilterContainer>
    );
};

export default MapSearchNestedFilterContent;

const MapSearchNestedFilterContainer = styled.div`
    margin-top: 35px;
    max-height: calc(100vh - 150px);
    overflow: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.color.yellow};
        border-radius: 4px;
    }
`;
