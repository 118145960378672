import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';
import Analytics from '../../../../../../lib/user-analytics';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSelectedIsSearchActive,
    selectSelectedSearchOrderBy,
} from '../../../../../../store/Map/MapSelection/selectors';
import { actionSetSelectedSearchOrderBy } from '../../../../../../store/Map/MapSelection/actions';

export const orderByOptions = [
    { value: 'CREATED', label: 'Latest uploads' },
    { value: 'POPULARITY', label: 'Most popular' },
    { value: 'RELEVANCE', label: 'Best match by keywords' },
    { value: 'COMMENTS', label: 'Most commented' },
];

export const ORDER_BY_DEFAULT = 'AREA_SIMILARITY';
export const ORDER_BY_KEYWORDS = 'RELEVANCE';

const MapSearchOrderByFilter = () => {
    const [showFilters, setShowFilters] = useState(true);
    const [orderBy, setOrderBy] = useState<string | undefined>(undefined);

    const searchOrderBy = useSelector(selectSelectedSearchOrderBy);
    const isSearchActive = useSelector(selectSelectedIsSearchActive);

    const dispatch = useDispatch();

    const handleCategorySelect = (orderValue: string) => {
        if (searchOrderBy === orderValue) {
            setOrderBy(undefined);
            dispatch(actionSetSelectedSearchOrderBy(undefined));
        } else {
            setOrderBy(orderValue);
            dispatch(actionSetSelectedSearchOrderBy(orderValue));
            Analytics.Event('Side Drawer', `Clicked OrderBy ${orderValue}`);
        }
    };

    useEffect(() => {
        if (isSearchActive && !searchOrderBy) {
            setOrderBy(ORDER_BY_KEYWORDS);
        } else {
            setOrderBy(searchOrderBy || ORDER_BY_DEFAULT);
        }
    }, [isSearchActive, searchOrderBy]);

    return (
        <React.Fragment>
            <DrawFilter onClick={() => setShowFilters(!showFilters)}>
                Order By
                <DrawFilterArrow open={showFilters} className="fa fa-chevron-up" />
            </DrawFilter>
            <DrawFilterCollapseContainer isOpen={showFilters}>
                {orderByOptions.map((option) => (
                    <CategoryFilterItem key={option.value} onClick={() => handleCategorySelect(option.value)}>
                        <Checkbox
                            type="checkbox"
                            checked={orderBy === option.value}
                            onChange={() => handleCategorySelect(option.value)}
                        />
                        {option.label}
                    </CategoryFilterItem>
                ))}
            </DrawFilterCollapseContainer>
        </React.Fragment>
    );
};

export default MapSearchOrderByFilter;

const DrawFilter = styled.div`
    color: white;
    font-size: 1.2rem;
    padding: 5px 0px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DrawFilterArrow = styled.i<{ open: boolean }>`
    display: inline-block;
    margin-right: 13px;
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.open ? '180deg' : '0deg')});
`;

const CategoryFilterItem = styled.div`
    color: ${(props) => props.theme.color.yellow};
    font-size: 1.2rem;
    padding: 5px 0px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: left;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

const Checkbox = styled.input`
    margin: 0px 10px;
`;

const DrawFilterCollapseContainer = styled(Collapse)`
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
`;
