import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BackgroundGradientAnimation from './Animations/background-gradient-animation';
import Register from '../Registration/register';
import MapCountTicker from './map-count-ticker';
import UriHelper from '../../lib/uri-helper';
import { SideDrawerMode } from '../../store/SideDrawer/model';
import { selectLoggedIn } from '../../store/Account/selectors';
import { useSelector } from 'react-redux';
import ApiAnalytics from '../../api/api-analytics';

export const JoinPageParameters = [
    { value: '1', label: 'Climate' },
    { value: '2', label: 'Topographic' },
    { value: '3', label: 'Elevation' },

    { value: '4', label: 'Economic' },
    { value: '5', label: 'Environment' },
    { value: '6', label: 'Geology' },
    { value: '7', label: 'Historical' },
    { value: '8', label: 'Imagery' },
    { value: '9', label: 'Landcover' },
    { value: '10', label: 'Nautical' },
    { value: '11', label: 'Political' },
    { value: '12', label: 'Radar' },
    { value: '13', label: 'Terrain' },
    { value: '15', label: 'Transport ' },
    { value: '16', label: 'Urban' },
];

const Join = () => {
    const isLoggedIn = useSelector(selectLoggedIn);
    const [hasPageJustLoaded, setHasPageJustLoaded] = useState(true);
    const [label, setLabel] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState('');

    const handlePageLoaded = (label: string | undefined) => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'View', label);
    };

    const handlePageLoadedWhileAlreadyLoggedIn = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'User already logged in', label);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    const handleSuccessfulRegistration = () => {
        ApiAnalytics.postAnalyticsEvent('JoinPage', 'Registered', label);
        UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
    };

    useEffect(() => {
        setTimeout(() => {
            setHasPageJustLoaded(false);
        }, 1000);
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const parameter = urlParams.get('q');
        const label = JoinPageParameters.find((param) => param.value === parameter)?.label;
        setLabel(label);
        handlePageLoaded(label);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            if (hasPageJustLoaded) {
                handlePageLoadedWhileAlreadyLoggedIn();
            } else {
                handleSuccessfulRegistration();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    return (
        <Container>
            <BackgroundGradientAnimation />
            <LeftContainer>
                <Title>
                    Find the {label ? <span>{label}</span> : null} maps
                    <br /> you are looking for
                </Title>
                <LeftContainerBlock>
                    <ul>
                        <li>World's fasted growing collection of online maps</li>
                        <li>
                            Over <MapCountTicker /> maps ... and growing!
                        </li>
                        <li>Trusted by BBC, New York Times and Wall Street Journal</li>
                    </ul>
                </LeftContainerBlock>
                <ImageContainer>
                    <Image src="/assets/join-page/asset1.jpg" />
                    <Image src="/assets/join-page/asset2.jpg" />
                    <Image src="/assets/join-page/asset3.gif" />
                </ImageContainer>
            </LeftContainer>
            <RightContainer>
                <RegisterContainer>
                    <Register
                        email={email}
                        onEmailChange={(email) => setEmail(email)}
                        onClose={() => ''}
                        handleClickLogin={() => ''}
                        onRegisterSuccess={() => {
                            null;
                        }}
                        hideFooter={true}
                    />
                </RegisterContainer>
            </RightContainer>
        </Container>
    );
};

export default Join;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin: 180px auto 0 auto;
    width: 66vw;
    height: 66vh;
    min-height: 600px;
    border: 1px solid #eed926;
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const Title = styled.h2`
    color: ${({ theme }) => theme.color.white};
    font-size: 2.5rem;
    margin-top: 60px;
    text-align: center;
    width: 100%;

    span {
        color: ${({ theme }) => theme.color.yellow};
    }
`;

const LeftContainer = styled.div`
    background: rgba(0, 0, 0, 0.5);
    width: 33vw;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const LeftContainerBlock = styled.div`
    margin: 40px;

    li {
        color: ${({ theme }) => theme.color.white};
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 20px;
    }
`;

const RightContainer = styled.div`
    width: 33vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RegisterContainer = styled.div`
    width: 390px;
    min-height: 553px;
`;

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    max-width: 25%;
    height: 90%;
    margin: 10px;
`;
